import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import {getVer} from '../ver';

class ReloadWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            ver: props.ver,
        }

        this.handleReload = this.handleReload.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleReload() {
        if (this.state.ver == undefined) {
            getVer().then(r => {
                window.forceReload(r);
            });
        } else {
            window.forceReload(this.state.ver);
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.time !== prevProps.time) {
            this.setState({
                open: true,
                ver: this.props.ver,
            })
        }
    }

    render() { 
        return (
            <Dialog
                open={this.state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Задачник обновился, нужно обновить кэш вашего браузера!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        На сервере доступна новая версия Задачника, вам нужно сейчас обновить кэш своего браузера, либо позже сделать это самостоятельно, нажав на кнопку "Обновить задачник".
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Обновить позже
                    </Button>
                    <Button onClick={this.handleReload} color="primary" autoFocus>
                        Обновить сейчас
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
 
export default (time, ver) => {
    
    if (!document.getElementById('reload_modal_window')) {
        let reloadModalWindow = document.createElement('div');
        reloadModalWindow.id = 'reload_modal_window';
        reloadModalWindow.setAttribute('data-reactel', '1');
        document.querySelector('body').appendChild(reloadModalWindow);
    }

    ReactDOM.render(<ReloadWindow time={time} ver={ver} />, document.getElementById('reload_modal_window'));
}
