// import React from 'react';
// import ReactDOM from 'react-dom';
// import VersionElement from './view/viewVer.jsx';
// import Game from '../GameModule/index.js';

// function getVer() {
    
//     fetch('/php/module/Version/VersionModel.php')
//         .then(response => response.json())
//         .then(r => {
//             let ver = `${r.major}.${r.minor}.${r.build}.${r.commits}`;
//             ReactDOM.render(<VersionElement version={ver}/>, document.querySelector('#pageContent'));
//         })
// }

// getVer();

// let verblock = document.createElement('div');
// verblock.id = 'verblock';
// verblock.setAttribute('data-reactel', '1');
// document.querySelector('.footer').appendChild(verblock);

// (async () => {
//     let response = await fetch('/jsajax/VerModule/verController');
//     let r = await response.json();
//     if (r.success) {
//         const { major, minor, build, commit } = r.res.data;
//         let ver = `${major}.${minor}.${build}.${commit}`;
//         ReactDOM.render(<VersionElement version={ver}/>, document.querySelector('#verblock'));
//     }
// })();


async function getVer() {
    let response = await fetch('/jsajax/VerModule/verController');
    let r = await response.json();
    if (r.success) {
        const { major, minor, build, commit } = r.res.data;
        let ver = `${major}.${minor}.${build}.${commit}`;
        return ver;
    } else {
        return false;
    }
}


export {getVer};