import ReactDOM from 'react-dom';
import {getVer} from '../VersionModule/ver';
import ReloadWindow from '../VersionModule/view/reloadWindow.jsx';

const loader = {
    use: function(moduleName, fileName, callBack) {
        // TODO: filename убрать!!!
        // let chunkName = moduleName.charAt(0).toLowerCase() + moduleName.substring(1) + fileName.split('.').slice(0, -1).join('.');
        // /* webpackChunkName: "${chunkName}" */

        function callFunction(module) {
            if (typeof(callBack) == 'function') {
                callBack(module);
            }
        }

        switch (moduleName) {
          case "VersionModule":
            import(
              /* webpackChunkName: "ver" */ "./../VersionModule/ver.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "checkList":
            import(
              /* webpackChunkName: "checkList" */ "./../checkList/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "meetingMsg":
            import(
              /* webpackChunkName: "meetingMsg" */ "./../MeetingMsg/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "GanttModule":
            import(
              /* webpackChunkName: "gantt" */ "./../GanttModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "reportFinishedTasks":
            import(
              /* webpackChunkName: "finishedTask" */ "./../reportFinishedTasks/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "ProfileNew":
            import(
              /* webpackChunkName: "profileNew" */ "./../profileNewModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "GanttDevModule":
            import(
              /* webpackChunkName: "ganttdev" */ "./../GanttDevModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "GanttSyncModule":
            import(
              /* webpackChunkName: "ganttsync" */ "./../GanttSyncModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "KanbanSmartWebcomponentsModule":
            import(
              /* webpackChunkName: "kanbansmart" */ "./../KanbanSmartWebcomponentsModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "GridSmartModule":
            import(
              /* webpackChunkName: "gridsmart" */ "./../GridSmartModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "ChecklistModule":
            import(
              /* webpackChunkName: "checklistmodule" */ "./../ChecklistModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;

          case "ChecklistSubtasksModule":
            import(
              /* webpackChunkName: "checklistsubtasksmodule" */ "./../ChecklistModule/subtasks.js"
            ).then((module) => {
              callFunction(module);
            });
            break;

          case "ItServicesModule":
            import(
              /* webpackChunkName: "itservices" */ "./../ItServicesModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "Report10Module":
            import(
              /* webpackChunkName: "report10" */ "./../ReportsModule/Report10/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "Report2Module":
            import(
              /* webpackChunkName: "report2" */ "./../ReportsModule/Report2/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "Report5Module":
            import(
              /* webpackChunkName: "report5" */ "./../ReportsModule/Report5/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "Report55Module":
            import(
              /* webpackChunkName: "report5" */ "./../ReportsModule/Report55/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "ProjectTasksModule":
            import(
              /* webpackChunkName: "projecttasksmodule" */ "./../ProjectTasksModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "ProjectPlansModule":
            import(
              /* webpackChunkName: "projectplanmodule" */ "./../ProjectPlansModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "AutoTasksOptionsModule":
            import(
              /* webpackChunkName: "autotasksoptionsmodule" */ "./../AutoTasksModule/Options/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "SettingsRolesModule":
            import(
              /* webpackChunkName: "settingsrolesmodule" */ "./../SettingsRolesModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "SettingsTaskParamsModule":
            import(
              /* webpackChunkName: "settingstaskparamsmodule" */ "./../SettingsTaskParamsModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "SettingsTagsModule":
            import(
              /* webpackChunkName: "settingstagsmodule" */ "./../SettingsTagsModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "SettingsUsersModule":
            import(
              /* webpackChunkName: "settingsusersmodule" */ "./../SettingsUsersModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "QuestModule":
            import(
              /* webpackChunkName: "questmodule" */ "./../QuestModule/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "ProjectOutboxesModule":
            import(
              /* webpackChunkName: "projectoutboxesmodule" */ "./../ProjectOutboxesModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "AutotasksTestsModule":
            import(
              /* webpackChunkName: "autotaskstestsmodule" */ "./../AutoTasksModule/Tests/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "AutotasksPopupComponent":
            import(
              /* webpackChunkName: "autotaskspopupcomponent" */ "./../AutoTasksModule/Options/AutotasksPopupComponent.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "PriceListComponent":
            import(
              /* webpackChunkName: "pricelistcomponent" */ "./../PriceListModule/index.js"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "PriceModule":
            import(
              /* webpackChunkName: "pricemodule" */ "./../PriceModule/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
          case "QuestPopup":
            import(
              /* webpackChunkName: "questpopup" */ "./../QuestModule/QuestTable/components/popupQuest/index.jsx"
            ).then((module) => {
              callFunction(module);
            });
            break;
        }

    },
    killReact: function(dataAttr = 'reactel') {
        document.querySelectorAll(`[data-${dataAttr}="1"]`).forEach((item) => {
            ReactDOM.unmountComponentAtNode(item);
        });
    },
    killReactChangePage: function(dataAttr = 'reactelpage') {
        document.querySelectorAll(`[data-${dataAttr}="1"]`).forEach((item) => {
            ReactDOM.unmountComponentAtNode(item);
            item.removeAttribute(`data-${dataAttr}`);
        });
    },
    checkVer: function() {
        if (localStorage.getItem('needToReload') == "true") {
            loader.fireWindowReload();
            return true;
        }

        getVer().then(r => {
            let ver = localStorage.getItem('ver');
            if (r !== ver) {
                localStorage.setItem('needToReload', true);
                loader.fireWindowReload(r);
            }
        });
    },
    fireWindowReload: function(ver) {
        import(/* webpackChunkName: "reloadWindow" */ './../VersionModule/view/reloadWindow.jsx').then(module => {
            module.default(new Date().getTime(), ver);
        });
    }
};

window.loader = loader;
